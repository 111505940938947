import { Player } from "@entities/player";
import { SocialNetwork } from "@entities/social-network/model/social-network.model";
import { SocialNetworkLink } from "@features/social-network-link";
import { Field, Input, Label } from "@headlessui/react";
import type { ApiClientResponse } from "@lobby/api-client";
import { Lobby } from "@lobby/core/entities/lobby";
import { useTranslate } from "@lobby/ocb-intl";
import { APIError, useErrorTranslate } from "@shared/lib";
import { Button, SVGIcon } from "@shared/ui";
import { Link } from "@tanstack/react-router";
import { useState } from "react";
import { AuthEndpointsContainer, Separator } from "./components";

interface ILoginFormProps {
  className?: string;
}

export function LoginForm({ className }: ILoginFormProps) {
  // const [email, setEmail] = useState("");
  const [login, setLogin] = useState("");
  const [password, setPassword] = useState("");

  const [isPasswordVisible, setIsPasswordVisible] = useState(false);
  const [validationError, setValidationError] = useState("");

  const { $t } = useTranslate();
  const { formatMessage: translateError } = useErrorTranslate();

  const { data: lobbyData } = Lobby.useLobby();
  const { mutate: loginMutate, isPending } = Player.useLogIn();
  const { data: authEndpointsData } = SocialNetwork.useAuthEndpoints();

  const authEndpoints = authEndpointsData?.result?.data ?? [];
  const isRegistrationEnabled = lobbyData?.registration.enabled ?? false;

  function handleSubmit(ev: React.FormEvent<HTMLFormElement>) {
    ev.preventDefault();

    setValidationError("");

    const form = new FormData(ev.currentTarget);

    if (validateForm(form)) {
      logIn(form);
    }
  }

  function logIn(form: FormData) {
    loginMutate(
      {
        login: form.get("login") as string,
        password: form.get("password") as string,
      },
      { onSuccess: handleLogInSuccess },
    );
  }

  function handleLogInSuccess(data: ApiClientResponse<"Player.logIn">) {
    const error = data.error;
    if (error) {
      const message = translateError(new APIError(error.message, { code: error.code }));
      setValidationError(message);
    }
  }

  function validateForm(form: FormData) {
    return form.has("login") && form.has("password");
  }

  return (
    <div className="text-sm lg:text-base">
      <form className={className} onSubmit={handleSubmit}>
        {/* <Field className="flex flex-col">
          <Label className="font-bold">{$t({ defaultMessage: "E-mail" })}:</Label>
          <Input
            className="input border-shark/80 border-1"
            name="email"
            type="email"
            value={email}
            onChange={(ev) => setEmail(ev.target.value)}
            autoComplete="email"
            autoCapitalize="none"
            minLength={3}
            maxLength={255}
            autoFocus
            required
          />
        </Field> */}

        <Field className="mt-3 flex flex-col">
          <Label className="font-bold">{$t({ defaultMessage: "Login" })}:</Label>
          <Input
            className="input border-1 border-shark/80"
            name="login"
            type="text"
            value={login}
            onChange={(ev) => setLogin(ev.target.value)}
            minLength={3}
            maxLength={255}
            autoCapitalize="none"
            autoFocus
            required
          />
        </Field>

        <Field className="mt-3 flex flex-col">
          <Label className="font-bold">{$t({ defaultMessage: "Password" })}:</Label>
          <div className="relative">
            <Input
              className="input w-full border-1 border-shark/80 pr-13"
              name="password"
              type={isPasswordVisible ? "text" : "password"}
              value={password}
              onChange={(ev) => setPassword(ev.target.value)}
              autoComplete="current-password"
              minLength={6}
              maxLength={255}
              required
            />
            <div
              className="-translate-y-1/2 absolute top-1/2 right-0 p-3 text-shark hover:cursor-pointer hover:text-shark/80 dark:text-mercury dark:hover:text-mercury/80"
              onClick={() => setIsPasswordVisible((state) => !state)}>
              {isPasswordVisible ? <SVGIcon name="openedEye" /> : <SVGIcon name="closedEye" />}
            </div>
          </div>
        </Field>

        {validationError && (
          <p className="my-3 text-center text-alizarin-crimson leading-none">{validationError}</p>
        )}

        {isRegistrationEnabled && (
          <div className="my-3 text-center">
            <Link
              className="text-big-stone underline hover:cursor-pointer hover:text-steel-blue dark:text-mercury dark:hover:text-lochmara"
              search={{ modal: "password-forgot" }}
              replace>
              {$t({ defaultMessage: "Forgot password?" })}
            </Link>
          </div>
        )}

        <div className="mt-5">
          <Button className="mx-auto" type="primary" htmlType="submit" loading={isPending}>
            {$t({ defaultMessage: "Log In" })}
          </Button>
        </div>
      </form>

      {authEndpoints.length > 0 && (
        <>
          <Separator>{$t({ defaultMessage: "or" })}</Separator>
          <AuthEndpointsContainer>
            {authEndpoints.map(({ name, stringId, url }) => (
              <SocialNetworkLink key={stringId} name={name} stringId={stringId} url={url} />
            ))}
          </AuthEndpointsContainer>
        </>
      )}
    </div>
  );
}
