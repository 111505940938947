import { clsx } from "clsx";

import themeLogoLight from "@app/assets/dark_VEGAS.png";
import themeLogoDark from "@app/assets/light_VEGAS.png";

export function Logo({ className }: { className?: string }) {
  return (
    <div className={clsx(className, "*:size-full [aspect-ratio:364/89]")}>
      <img
        src={themeLogoDark}
        alt="vegas365 logo"
        className="object-contain size-full hidden dark:block"
      />
      <img
        src={themeLogoLight}
        alt="vegas365 logo"
        className="object-contain size-full dark:hidden"
      />
    </div>
  );
}
